// import { BRAND } from 'Constants/common'
// import AppConfig from 'Constants/AppConfig'
import request from './request'

export default {
  data () {
    return {

    }
  },
  mixins: [request],
  methods: {
    async fetchRoles () {
      return new Promise((resolve, reject) => {
        // Fetch roles
        this.request('GET', '/users/roles', {}, ({ data }) => {
          resolve(data)
        }, null, (error) => {
          reject(error)
        })
      })
    },
    getList () {
      return this.list
    }
  },
  computed: {

  }
}
