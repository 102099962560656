<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      :is-disabled-deleted-filter="true"
      endpoint="web-notifications"
      row-click-path="/tables/web-notifications"
      @create="createWebNotification()"
      @delete="id => deleteWebNotification(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-form
          ref="webNotificationForm"
          v-model="valid"
          lazy-validation>
          <v-container grid-list-xl>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.title"
                  :rules="[v => Boolean(v) || 'Please provide a title']"
                  type="text"
                  label="Title"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.text"
                  :rules="[v => Boolean(v) || 'Please provide a text']"
                  type="text"
                  label="Text"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-select
                  :items="typesList"
                  v-model="editDialog.data.type"
                  :rules="[v => Boolean(v) || 'Please select a type']"
                  label="Type"
                />
              </v-flex>
              <v-flex
                xs12
                sm2>
                <v-switch
                  v-model="editDialog.data.enabled"
                  label="Enabled"
                />
              </v-flex>
              <v-flex
                v-if="roleList"
                xs12
                sm12>
                <v-select
                  :items="Object.keys(roleList)"
                  :rules="[v => v.length > 0 || 'Please select at least 1 user role']"
                  v-model="editDialog.data.roles"
                  multiple
                  label="Roles"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import { WEB_NOTIFICATION_TYPES, TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import Roles from '../../../helpers/roles'

export default {
  components: { CrudTable },
  mixins: [Request, Roles],
  data () {
    return {
      valid: true,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Type', value: 'type' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Enabled', value: 'enabled' },
        { text: 'Roles', value: 'roles' },
        { text: '', value: null }
      ],
      typesList: WEB_NOTIFICATION_TYPES,
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      roleList: []
    }
  },
  async beforeMount () {
    try {
      this.roleList = await this.fetchRoles()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    createWebNotification () {
      this.editDialog.display = true
      this.editDialog.type = 'create'
      this.editDialog.data = {}

      this.editDialog.saveFunction = () => {
        if (this.$refs.webNotificationForm.validate()) {
          let body = {
            data: {
              'title': this.editDialog.data.title,
              'text': this.editDialog.data.text,
              'type': this.editDialog.data.type,
              'enabled': this.editDialog.data.enabled,
              'roles': this.editDialog.data.roles
            }
          }

          this.request('POST', `/web-notifications`, body, ({ data }) => {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Web notification has been created'
            })
          })
        }
      }
    },
    deleteWebNotification (id) {
      this.request('DELETE', `/web-notifications/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Web notification has been deleted'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
